export let JSONfn = {
        stringify: function(e) {
            return JSON.stringify(e, 
                function(e, t) {
                    if(t instanceof Function || typeof t == "function" ) {
                        return t.toString()
                    }
                    if(t instanceof RegExp) {
                        return "_PxEgEr_" + t 
                    }
                    return t
                }
            )
        },
        parse: function(str, date2obj?) {
            let iso8061: RegExp;
            if (date2obj) {
                iso8061 = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)Z$/; 
            }
            

            return JSON.parse(str, function(key, value) {
                var prefix;
                if(typeof value != "string") { 
                    return value
                }
                if(value.length < 8) {
                    return value
                }
                prefix = value.substring(0, 8);
                if(date2obj && value.match(iso8061)) {
                    return new Date(value)
                }
                if(prefix === "function") {
                    return eval("("+value+")");
                }
                if(prefix === "_PxEgEr_") { 
                    return eval(value.slice(8))} {
                        return value;
                    }
                }
            );
        },
        clone: function(e, t) {
            return exports.parse(exports.stringify(e), t);
        }
}
