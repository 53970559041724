import * as $ from "jquery";
import { IGameData } from "./IGameData";
import { ISettings } from "./ISettings";
import { set_score_pos } from "./Gamefunctions";


export class Canvas {
	public static scaleCanvas(data: IGameData, gameSettings: ISettings) {

		data.canvas.width = $(window).width();
		data.canvas.height = $(window).height();

		if (data.canvas.height > data.canvas.width) {
			gameSettings.scale = (data.canvas.width / 800) * gameSettings.baseScale;
		} else {
			gameSettings.scale = (data.canvas.height / 800) * gameSettings.baseScale;
		}

		data.trueCanvas = {
			width: data.canvas.width,
			height: data.canvas.height
		};

		if (window.devicePixelRatio) {
			var cw = +$("#canvas").attr('width');
			var ch = +$("#canvas").attr('height');

			$("#canvas").attr('width', cw * window.devicePixelRatio);
			$("#canvas").attr('height', ch * window.devicePixelRatio);
			$("#canvas").css('width', cw);
			$("#canvas").css('height', ch);

			data.trueCanvas = {
				width: cw,
				height: ch
			};

			data.ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
		}
		this.setBottomContainer(data, gameSettings);
		set_score_pos();
	}

	private static setBottomContainer(data: IGameData, settings: ISettings) {
		var buttonOffset = $("#buttonCont").offset().top;
		var playOffset = data.trueCanvas.height / 2 + 100 * settings.scale;
		var delta = buttonOffset - playOffset - 29;
		if (delta < 0) {
			$("#bottomContainer").css("margin-bottom", "-" + Math.abs(delta) + "px");
		}
	}
}
