import { Game, Platform } from "../GamePlatform";
import { init, isInfringing } from "./Gamefunctions";
import { Canvas } from "./Canvas";
import { GameState } from "./GameState";
import { IGameData } from "./IGameData";
import { clearSaveState, exportSaveState, isStateSaved } from "./save-state";
import { BlurCanvas, showPause } from "./view";
import { ISettings } from "./ISettings";
import { render } from "./render";
import { update } from "./update";
import { JSONfn } from "./jsonfn";
import { setEvent } from "../Platform";

// Used on global scale
export let gameData: IGameData = undefined;
export let settings: ISettings = undefined;

// Main class for the game
export class Hextrix implements Game {
	private platform: Platform;

	constructor() {
		settings = this.loadSettings();

		this.platform = new Platform();
		this.platform.preloadSounds(settings);
		this.platform.init(this, settings, ["messageFont", "scoreFont"]);

		this.initialize();
	}

	private initialize(paramA?: number) {
		const framerate = 60;

		// Load basic data object
		gameData = this.loadGameData();

		// Load default settings based on device

		// Set canvas full screen
		Canvas.scaleCanvas(gameData, settings);

		// Show start screen
		// this.setStartScreen();

		this.addEventHandlers();

		// Initialize objects
		// Prepare first game to allow drawings
		init(false);

		// Restart is allowed
		gameData.canRestart = 1;
		this.nextFrame();
		render();
		this.platform.ready();

		// $(window).unload(function() {
		// 	if (gameState == 1 || gameState == -1 || gameState === 0) localStorage.setItem("saveState", exportSaveState());
		// 	else localStorage.setItem("saveState", "{}");
		// });

		// addKeyListeners();
	}

	private proloader() {
		// let anyFont = new Font();
		// anyFont.src = "fonts/fileName.otf";
		// anyFont.onload = function () {
		// console.log("font loaded");
		// }
	}

	private addEventHandlers() {
		setEvent(
			gameData.canvas,
			this.handleClick.bind(this),
			this.handleTap.bind(this)
		);
		// if (settings.platform == "mobile") {
		//     document.body.addEventListener('touchstart', this.handleTap.bind(this), false);
		// } else {
		//     try {
		//         document.body.removeEventListener('mousedown', this.handleClick.bind(this), false);
		//     } catch (e) {
		//         console.error('error', e);
		//     }
		//     document.body.addEventListener('mousedown', this.handleClick.bind(this), false);
		// }
		// console.log('register others');

		document.addEventListener(
			"blur",
			(e) => {
				this.pauseGame();
				// Pause on blur?
			},
			{ passive: false }
		);

		window.addEventListener(
			"visibilitychange",
			(e) => {
				if (document.visibilityState === "hidden") {
					this.pauseGame();
				}
			},
			{ passive: false }
		);

		// Safari
		document.addEventListener(
			"pagehide",
			(event) => {
				this.pauseGame();
			},
			{ passive: false }
		);

		window.addEventListener("resize", (e) => {
			// console.log('resize');
			Canvas.scaleCanvas(gameData, settings);
		});
	}

	private pauseGame() {
		if (gameData.gameState !== GameState.PLAY) {
			// console.log('PAUSE not allowd');
			return;
		}
		gameData.gameState = GameState.PAUSED;
		showPause(true);
		gameData.prevGameState = GameState.PLAY;
	}

	private handleTap(e: TouchEvent) {
		e.preventDefault();
		e.stopPropagation();
		this.handleClickTap(
			e.changedTouches[0].clientX,
			e.changedTouches[0].clientY
		);
	}

	private handleClick(e: MouseEvent) {
		e.preventDefault();
		e.stopPropagation();
		this.handleClickTap(e.clientX, e.clientY);
	}
	private resumeGame() {
		if (gameData.gameState !== GameState.PAUSED) {
			// console.log('RESUME not allowed');
			return;
		}
		// console.log('resume');
		showPause(false);
		gameData.gameState = GameState.RESUME;
		this.nextFrame();
	}

	private handleClickTap(x: number, y: number) {
		if (gameData.gameState === GameState.PAUSED) {
			this.resumeGame();
			return;
		}
		if (gameData.gameState !== GameState.PLAY) {
			// console.log('not playing on', x, y);
			return;
		}
		// console.log('handleClick or tap on', x, y);
		// if (x < 120 && y < 83 && $('.helpText').is(':visible')) {
		// 	showHelp();
		// 	return;
		// }
		var radius = settings.hexWidth;
		var halfRadius = radius / 2;
		var triHeight = radius * (Math.sqrt(3) / 2);
		var Vertexes = [
			[radius, 0],
			[halfRadius, -triHeight],
			[-halfRadius, -triHeight],
			[-radius, 0],
			[-halfRadius, triHeight],
			[halfRadius, triHeight],
		];
		Vertexes = Vertexes.map(function (coord) {
			return [
				coord[0] + gameData.trueCanvas.width / 2,
				coord[1] + gameData.trueCanvas.height / 2,
			];
		});

		if (
			!gameData.MainHex ||
			gameData.gameState === 0 ||
			gameData.gameState == -1
		) {
			return;
		}

		if (x < window.innerWidth / 2) {
			gameData.MainHex.rotate(1);
		}
		if (x > window.innerWidth / 2) {
			gameData.MainHex.rotate(-1);
		}
	}

	private animLoop() {
		const spd = 1;
		let now: number;
		let dt: number;
		switch (gameData.gameState) {
			case GameState.RESUME:
				// console.log('RESUME');
				gameData.lastTime = Date.now();
				// gameData.startTime = Date.now();
				gameData.gameState = GameState.PLAY;
				break;
			case GameState.PAUSED:
				// No changes to objects, render current state
				render();
				return;
				break;

			case GameState.START:
				// No changes to objects, render current state
				render();
				break;
			case GameState.PLAY:
				// During play
				render();

				now = Date.now();
				dt = ((now - gameData.lastTime) / 16.666) * gameData.rush;

				if (spd > 1) {
					dt *= spd;
				}

				// If there is not a delay, call update function
				if (gameData.MainHex.delay) {
					gameData.MainHex.delay--;
				} else {
					update(dt);
				}

				// Register last time to calculate delta
				gameData.lastTime = now;

				// check if the user is gameover
				let isGameOver = this.checkGameOver();

				if (isGameOver && !gameData.importing) {
					this.gameover();
				}

				break;

			case GameState.GAMEOVER:
				now = Date.now();
				dt = ((now - gameData.lastTime) / 16.666) * gameData.rush;

				update(dt);
				render();
				gameData.lastTime = now;

				break;

			case 3:
				// not fuond function
				// fadeOutObjectsOnScreen();
				render();

				break;

			// case 4:
			// 	setTimeout(function() {
			// 		initialize(1);
			// 	}, 1);
			// 	render();
			// 	return;

			// default:
			// 	console.log('default anim frame loop action')
			// 	initialize();
			// 	setStartScreen();
			// 	break;
		}

		// Request next animation frame
		this.nextFrame();

		// Store lastTime if not playing or gameover
		if (
			gameData.gameState !== GameState.PLAY &&
			gameData.gameState !== GameState.GAMEOVER
		) {
			gameData.lastTime = Date.now();
		}
	}

	private nextFrame() {
		window.requestAnimationFrame(this.animLoop.bind(this));
	}

	private checkGameOver() {
		for (let i = 0; i < gameData.MainHex.sides; i++) {
			if (isInfringing(gameData.MainHex)) {
				settings.ending_block = false;
				BlurCanvas(true);

				return true;
			}
		}
		return false;
	}

	private gameover() {
		let saveState = localStorage.getItem("saveState") || "{}";
		saveState = JSONfn.parse(saveState);
		gameData.gameState = GameState.GAMEOVER;

		// if ($('#helpScreen').is(':visible')) {
		//     $('#helpScreen').fadeOut(150, "linear");
		// }

		// if ($('#pauseBtn').is(':visible')) $('#pauseBtn').fadeOut(150, "linear");
		// if ($('#restartBtn').is(':visible')) $('#restartBtn').fadeOut(150, "linear");
		// if ($('#openSideBar').is(':visible')) $('.openSideBar').fadeOut(150, "linear");

		// Do not allow restart
		gameData.canRestart = 0;
		this.platform.gameover(gameData.score);
		// Remove state from storage
		clearSaveState();

		// Enable restart after x seconds
		// this.prepareNextGame();
	}

	restart() {
		gameData.canRestart = 1;
		init();
		this.platform.ready();
	}

	private setStartScreen() {
		// console.log('setStartScreen', gameData.gameState);
		// $('#startBtn').show();

		// Prepare objects
		init(false);

		// console.log('setStartScreen', gameData.gameState);

		// Check if there is a saved state
		// If true, remove the importing flag
		if (isStateSaved()) {
			// console.log('saved state available');
			// Not importing
			gameData.importing = 0;
		} else {
			// console.log('No saved state available');
			// No saved state, so importing a new game?
			gameData.importing = 1;
		}

		// Hide pause and restart buttons, show the start button
		// We are ready to play
		// $('#pauseBtn').hide();
		// $('#restartBtn').hide();
		// $('#startBtn').show();

		// State is now ready to start
		// gameData.gameState = GameState.START;
		// render();
		// this.nextFrame();
		// this.start();
	}

	// Start the game
	public play() {
		if (gameData.gameState !== GameState.START) {
			console.warn("Game not in start state", gameData.gameState);
			return;
		}
		if (!gameData.canRestart) {
			console.warn("restart not allowed");
			return false;
		}
		console.log("START GAME");
		// Set state to play to begin
		gameData.gameState = GameState.PLAY;
		this.platform.gamestarted();
	}

	public mute(mute: boolean) {
		console.log(mute);
		Howler.mute(mute);
	}

	private loadSettings(): ISettings {
		const DesktopSettings: ISettings = {
			os: "other",
			platform: "nonmobile",
			baseScale: 1,
			startDist: 340,
			creationDt: 19,
			scale: 1,
			prevScale: 1,
			hexWidth: 65,
			baseHexWidth: 87,
			baseBlockHeight: 20,
			blockHeight: 15,
			rows: 8,
			speedModifier: 0.65,
			creationSpeedModifier: 0.65,
			comboTime: 310,
			ending_block: undefined,
			overlayColor: "#FAFAFAB2",
			message: "Spel gepauzeerd",
			scoreFont: "Acme",
			path: "https://gamecdn.playenwin.com/wietse/hetrix/default/",
			swipeSound: "",
			matchSound: "",
			dropBlockSound: "",
			blockSpawnSound: "",
			scoreColor: "black",
			messageColor: "blue",
			messageFont: "Arial",
			messageFontSize: 50,
			scoreFontSize: 30,
			scoreOffsetY: 0,
		} as ISettings;

		let customSettings = DesktopSettings;
		const urlParams = new URLSearchParams(window.location.search);
		if (urlParams.get("s")) {
			const settings = atob(urlParams.get("s"))
				.split("\n")
				.filter((s) => s.trim() !== "")
				.map((s) => {
					return {
						name: s.split("=")[0].trim(),
						value: s.split("=")[1].trim(),
					};
				});

			settings.forEach((s) => {
				if (!isNaN(+s.value)) {
					customSettings[s.name] = +s.value;
				} else if (s.value === "true") {
					customSettings[s.name] = true;
				} else if (s.value === "false") {
					customSettings[s.name] = false;
				} else {
					customSettings[s.name] = s.value;
				}
			});
		}
		return customSettings;
		if (
			/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			return DesktopSettings;
		} else {
			return DesktopSettings;
		}
	}

	private loadGameData(): IGameData {
		const saveState = localStorage.getItem("saveState") || "{}";
		let canvas = document.getElementById("canvas") as HTMLCanvasElement;

		return {
			colors: ["#F0047F", "#F18A00", "#41B0F1", "#3FA652"],
			hexColorsToTintedColors: {
				"#F0047F": "rgb(240,4,17)",
				"#F18A00": "rgb(241,138,0)",
				"#41B0F1": "rgb(65,176,241)",
				"#3FA652": "rgb(63,166,82)",
			},

			rgbToHex: {
				"rgb(240,4,17)": "#F0047F",
				"rgb(241,196,15)": "#F18A00",
				"rgb(52,152,219)": "#41B0F1",
				"rgb(46,204,113)": "#3FA652",
			},

			rgbColorsToTintedColors: {
				"rgb(240,4,17)": "rgb(240,4,17)",
				"rgb(241,196,15)": "rgb(241,138,0)",
				"rgb(52,152,219)": "rgb(65,176,241)",
				"rgb(46,204,113)": "rgb(63,166,82)",
			},

			hexagonBackgroundColor: "rgba(200, 200, 200, 0.8)",
			hexagonBackgroundColorClear: "rgba(236, 240, 241, 0.5)",
			centerBlue: "rgba(36,55,70,0.5)",

			//
			rush: 1,

			angularVelocityConst: 4,
			scoreOpacity: 0,
			textOpacity: 0,

			op: saveState !== "{}" ? 1 : 0,
			textShown: false,
			saveState: saveState,
			history: [],
			score: 0,
			scoreAdditionCoeff: 1,
			prevScore: 0,
			blocks: [],

			gdx: 0,
			gdy: 0,
			devMode: 0,
			spawnLane: 0,
			importing: 0,

			lastTime: Date.now(),
			gameState: GameState.START,
			canvas: canvas,
			ctx: canvas.getContext("2d"),
			trueCanvas: {
				width: canvas.width,
				height: canvas.height,
			},

			// Not yet determined vars
			canRestart: false,
			prevGameState: GameState.START,
			MainHex: undefined,
			startTime: undefined,
		};
	}
}
