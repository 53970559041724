export function rotatePoint(x: number, y: number, theta: number) {
	var thetaRad = theta * (Math.PI / 180);
	var rotX = Math.cos(thetaRad) * x - Math.sin(thetaRad) * y;
	var rotY = Math.sin(thetaRad) * x + Math.cos(thetaRad) * y;

	return {
		x: rotX,
		y: rotY
	};
}

export function randInt(min: number, max: number) {
	return Math.floor((Math.random() * max) + min);
}

