import { Hex } from "./Hex";
import { Block } from "./Block";
import { GameState } from "./GameState";
import { WaveGen } from "./WaveGen";

import { BlurCanvas, hideText } from "./view";
import * as $ from "jquery";
import { gameData, settings } from "./Game";

export let waveone: WaveGen;
let infobuttonfading: boolean;

export function set_score_pos() {
	$("#container").css("margin-top", "0");
	var middle_of_container =
		$("#container").height() / 2 + $("#container").offset().top;
	var top_of_bottom_container = $("#buttonCont").offset().top;
	var igt = $("#highScoreInGameText");
	var igt_bottom = igt.offset().top + igt[0].offsetHeight;
	var target_midpoint = (top_of_bottom_container + igt_bottom) / 2;
	var diff = target_midpoint - middle_of_container;
	$("#container").css("margin-top", diff + "px");
}

export function toggleDevTools() {
	$("#devtools").toggle();
}

export function resumeGame() {
	gameData.gameState = GameState.PLAY;
	hideUIElements();
	// $('#pauseBtn').show();
	// $('#restartBtn').hide();
	gameData.importing = 0;
	gameData.startTime = Date.now();

	setTimeout(function () {
		if (
			gameData.gameState === GameState.PLAY ||
			gameData.gameState === GameState.PAUSED
		) {
			$("#openSideBar").fadeOut(150, "linear");
		}
	}, 7000);

	checkVisualElements();
}

export function checkVisualElements() {
	// if ($('#openSideBar').is(":visible")) $('#openSideBar').fadeOut(150, "linear");
	// if (!$('#pauseBtn').is(':visible')) $('#pauseBtn').fadeIn(150, "linear");
	$("#fork-ribbon").fadeOut(150);
	// if (!$('#restartBtn').is(':visible')) $('#restartBtn').fadeOut(150, "linear");
	// if ($('#buttonCont').is(':visible')) $('#buttonCont').fadeOut(150, "linear");
}

function hideUIElements() {
	// $('#pauseBtn').hide();
	// $('#restartBtn').hide();
	// $('#startBtn').hide();
}

interface ISafeState {
	score: number;
	hex: Hex;
	blocks: Array<Block>;
	wavegen: WaveGen;
	gdx;
	gdy;
}

/**
 *
 * @param forImport
 */
export function init(forImport?: boolean) {
	// console.log('call init with b= ', forImport);
	if (settings.ending_block && forImport === true) {
		// console.log('skip init');
		return;
	}

	// // State is set to {}
	// clearSaveState();

	// //checkVisualElements();
	// if (forImport) {
	// 	$("#pauseBtn").attr('src',"./images/btn_pause.svg");

	// 	setTimeout(function() {
	// 		$('#openSideBar').fadeOut(150, "linear");
	// 		infobuttonfading = false;
	// 	}, 7000);

	// 	clearSaveState();
	// 	checkVisualElements();
	// }

	// infobuttonfading = true;
	// $("#pauseBtn").attr('src',"./images/btn_pause.svg");
	// hideUIElements();

	// // Load state
	// let saveState = JSONfn.parse(localStorage.getItem("saveState") || "{}") as {
	// 	score: number,
	// 	hex: Hex,
	// 	blocks: Array<Block>,
	// 	wavegen: waveGen
	// };
	let saveState = {} as ISafeState;

	// Define heights
	settings.blockHeight = settings.baseBlockHeight * settings.scale;
	settings.hexWidth = settings.baseHexWidth * settings.scale;

	initGameData();
	// $("#restartBtn").hide();
	// $("#pauseBtn").show();
	if (saveState.hex) {
		pushSaveState(saveState);
		gameData.gameState = GameState.PLAY;
		gameData.MainHex.playThrough += 1;
	}

	// force sideLenth in MainHex
	gameData.MainHex.sideLength = settings.hexWidth;

	// not used?
	// comboTime = gameData.saveState.comboTime || 0;

	//  if block available, update heights and settled
	for (let i = 0; i < gameData.MainHex.blocks.length; i++) {
		for (var j = 0; j < gameData.MainHex.blocks[i].length; j++) {
			gameData.MainHex.blocks[i][j].height = settings.blockHeight;
			gameData.MainHex.blocks[i][j].settled = 0;
		}
	}

	//  if block available, update colors
	gameData.MainHex.blocks.map(function (i) {
		i.map(function (o) {
			if (gameData.rgbToHex[o.color]) {
				o.color = gameData.rgbToHex[o.color];
			}
		});
	});

	// Set mainhex y
	gameData.MainHex.y = -100;

	// Reset starttime
	gameData.startTime = Date.now();

	//
	waveone = saveState.wavegen || new WaveGen(gameData.MainHex);

	gameData.MainHex.texts = []; //clear texts
	gameData.MainHex.delay = 15;
	hideText();

	// Remove classname from canvas
	BlurCanvas(false);
}

function initGameData() {
	gameData.history = [];
	gameData.importing = 0;
	gameData.score = 0;
	gameData.prevScore = 0;
	gameData.spawnLane = 0;
	gameData.op = 0;
	gameData.gdx = 0;
	gameData.gdy = 0;

	gameData.scoreOpacity = 0;
	gameData.gameState = GameState.START;
	gameData.MainHex = new Hex(settings.hexWidth);
	gameData.blocks = [];
}

function pushSaveState(saveState: ISafeState) {
	gameData.score = saveState.score;
	gameData.MainHex = saveState.hex;
	gameData.gdx = saveState.gdx;
	gameData.gdy = saveState.gdy;

	let i;
	let block;
	if (saveState.blocks) {
		saveState.blocks.map(function (o) {
			if (gameData.rgbToHex[o.color]) {
				o.color = gameData.rgbToHex[o.color];
			}
		});

		// Add blocks from saved state
		for (i = 0; i < saveState.blocks.length; i++) {
			block = saveState.blocks[i];
			gameData.blocks.push(block);
		}
	}
}

export function addNewBlock(blocklane, color, iter, distFromHex?, settled?) {
	//last two are optional parameters
	iter *= settings.speedModifier;
	if (!history[gameData.MainHex.ct]) {
		history[gameData.MainHex.ct] = {};
	}

	history[gameData.MainHex.ct].block = {
		blocklane: blocklane,
		color: color,
		iter: iter,
	};

	if (distFromHex) {
		history[gameData.MainHex.ct].distFromHex = distFromHex;
	}
	if (settled) {
		// not used?
		// blockHist[gameData.MainHex.ct].settled = settled;
	}

	playSound(settings.blockSpawnSound);

	gameData.blocks.push(
		new Block(blocklane, color, iter, distFromHex, settled)
	);
}

export function playSound(soundKey?: string) {
	if (soundKey)
		(window as any).platform.playSound(settings.path + "/" + soundKey);
}

function exportHistory() {
	$("#devtoolsText").html(JSON.stringify(history));
	toggleDevTools();
}

export function isInfringing(hex) {
	for (var i = 0; i < hex.sides; i++) {
		var subTotal = 0;
		for (var j = 0; j < hex.blocks[i].length; j++) {
			subTotal += hex.blocks[i][j].deleted;
		}

		if (hex.blocks[i].length - subTotal > settings.rows) {
			return true;
		}
	}
	return false;
}

// export function showHelp() {
// 	if ($('#openSideBar').attr('src') == './images/btn_back.svg') {
// 		$('#openSideBar').attr('src', './images/btn_help.svg');
// 		if (gameData.gameState != 0 && gameData.gameState != -1 && gameData.gameState != 2) {
// 			$('#fork-ribbon').fadeOut(150, 'linear');
// 		}
// 	} else {
// 		$('#openSideBar').attr('src', './images/btn_back.svg');
// 		// if (gameData.gameState === 0 && gameData.gameState === -1 && gameData.gameState === 2) {
// 		// 	$('#fork-ribbon').fadeIn(150, 'linear');
// 		// }
// 	}

// 	$("#inst_main_body").html("<div id = 'instructions_head'>HOW TO PLAY</div><p>The goal of Hextris is to stop blocks from leaving the inside of the outer gray hexagon.</p><p>" + (settings.platform != 'mobile' ? 'Press the right and left arrow keys' : 'Tap the left and right sides of the screen') + " to rotate the Hexagon</p><p>Clear blocks and get points by making 3 or more blocks of the same color touch.</p><p>Time left before your combo streak disappears is indicated by <span style='color:#f1c40f;'>the</span> <span style='color:#e74c3c'>colored</span> <span style='color:#3498db'>lines</span> <span style='color:#2ecc71'>on</span> the outer hexagon.</p><p>Note that Hextris uses Monero mining for monetization.</p> <hr> <p id = 'afterhr'></p> By <a href='http://loganengstrom.com' target='_blank'>Logan Engstrom</a> & <a href='http://github.com/garrettdreyfus' target='_blank'>Garrett Finucane</a><br>Find Hextris on <a href = 'https://itunes.apple.com/us/app/id903769553?mt=8' target='_blank'>iOS</a> & <a href ='https://play.google.com/store/apps/details?id=com.hextris.hextris' target='_blank'>Android</a><br>More @ the <a href ='http://hextris.github.io/' target='_blank'>Hextris Website</a>");
// 	if (gameData.gameState == 1) {
// 		pause();
// 	}

// 	if($("#pauseBtn").attr('src') == "./images/btn_pause.svg" && gameData.gameState != 0 && !infobuttonfading) {
// 		return;
// 	}

// 	$("#openSideBar").fadeIn(150,"linear");
// 	$('#helpScreen').fadeToggle(150, "linear");
// }
