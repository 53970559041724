import { gameData, settings } from "./Game";
import { GameState } from "./GameState";


export function drawTimer() {
	if(gameData.gameState !== GameState.PLAY) {
		return;
	}

	let leftVertexes = [];
	let rightVertexes = [];
	if(gameData.MainHex.ct - gameData.MainHex.lastCombo < settings.comboTime) {
		for(let i = 0; i < 6; i++) {
			let done = (gameData.MainHex.ct - gameData.MainHex.lastCombo);
			if (done<(settings.comboTime)*(5-i)*(1/6)) {
				leftVertexes.push(calcSide(i,i+1,1,1));
				rightVertexes.push(calcSide(12-i,11-i,1,1));
			}
			else {
				leftVertexes.push(calcSide(i,i+1,1-((done*6)/settings.comboTime)%(1),1));
				rightVertexes.push(calcSide(12-i,11-i,1-((done*6)/settings.comboTime)%(1),1));
				break;
			}
		}
	}
	if(rightVertexes.length !== 0) {
		drawSide(rightVertexes);
	}

	if(leftVertexes.length !== 0) {
		drawSide(leftVertexes);
	}
	
}

function calcSide(startVertex,endVertex,fraction,offset){
	startVertex = (startVertex+offset)%12;
	endVertex = (endVertex+offset)%12;
	gameData.ctx.globalAlpha=1;
	gameData.ctx.beginPath();
	gameData.ctx.lineCap = "round";

	var radius = (settings.rows * settings.blockHeight) * (2/Math.sqrt(3)) + settings.hexWidth ;
	var halfRadius = radius/2;
	var triHeight = radius *(Math.sqrt(3)/2);
	var Vertexes =[
		[(halfRadius*3)/2,triHeight/2],
		[radius,0],
		[(halfRadius*3)/2,-triHeight/2],
		[halfRadius,-triHeight],
		[0,-triHeight],
		[-halfRadius,-triHeight],
		[-(halfRadius*3)/2,-triHeight/2],
		[-radius,0],
		[-(halfRadius*3)/2,triHeight/2],
		[-halfRadius,triHeight],
		[0,triHeight],
		[halfRadius,triHeight]
	].reverse();
	var startX = gameData.trueCanvas.width/2 + Vertexes[startVertex][0];
	var startY = gameData.trueCanvas.height/2 + Vertexes[startVertex][1];
	var endX = gameData.trueCanvas.width/2 + Vertexes[endVertex][0];
	var endY = gameData.trueCanvas.height/2 + Vertexes[endVertex][1];
		return [[startX,startY],[((endX-startX)*fraction)+startX,((endY-startY)*fraction)+startY]];
}

function drawSide(vertexes){
	if (gameData.gameState === GameState.START) {
		gameData.ctx.strokeStyle = gameData.hexColorsToTintedColors[gameData.MainHex.lastColorScored];
	} else {
		gameData.ctx.strokeStyle = gameData.MainHex.lastColorScored;
	}
	gameData.ctx.lineWidth =4*settings.scale;
	gameData.ctx.moveTo(vertexes[0][0][0],vertexes[0][0][1]);
	gameData.ctx.lineTo(vertexes[0][1][0],vertexes[0][1][1]);
	for(var i=1;i<vertexes.length;i++){
		gameData.ctx.lineTo(vertexes[i][1][0],vertexes[i][1][1]);
		gameData.ctx.moveTo(vertexes[i][1][0],vertexes[i][1][1]);
	}
	gameData.ctx.closePath();
	gameData.ctx.fill();
	gameData.ctx.stroke();
}
