export function setEvent(element: Element | Document, action: EventListenerOrEventListenerObject, touchAction?: EventListenerOrEventListenerObject) {
  if ('ontouchstart' in window) {
    if (touchAction) {
      element.addEventListener('touchstart', touchAction, { passive: false });
    } else {
      element.addEventListener('touchstart', action, { passive: false });
    }
  } else {
    element.addEventListener('mousedown', action, { passive: false });
  }
}

export function disableTouch() {
  return ;
  registerEvent('disabletouch');
  // Register gesturestart to prevent complex events
  document.addEventListener('gesturestart', (e) => {
   // e.preventDefault();
    registerEvent('gesturestart');
  }, { passive: false });

  // Handle default mouse down OR touch start to prevent pinch zoom etc.
  setEvent(document, (e: MouseEvent) => {
    registerEvent('prevent ' + e.type);
    //e.preventDefault();
  });

  // Catch move events
  if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
    window.document.addEventListener('touchmove',
      e => {
        registerEvent('ios touch move');
      //  e.preventDefault();
      }, { passive: false }
    );
  }
}

function registerEvent(name: string) {

  if (document.getElementById('messages')) {
    document.getElementById('messages').innerHTML = name + '<br>' + document.getElementById('messages').innerHTML;
  }
   if (top.document.getElementById('messages')) {
     top.document.getElementById('messages').innerHTML = name + '<br>' + top.document.getElementById('messages').innerHTML;
   }
}
