
export const GameState = {
	START: 0,
	PLAY: 1,
	GAMEOVER: 2,
	PAUSED: -1,
	STATE2: 2,
	STATEmin2: -2,
	RESUME: 5
}

;












// function handleTapBefore(e) {
// 	var x = e.changedTouches[0].clientX;
// 	var y = e.changedTouches[0].clientY;

// 	if (x < 120 && y < 83 && $('.helpText').is(':visible')) {
// 		showHelp();
// 		return;
// 	}
// }

// function handleClickBefore(e) {
// 	var x = e.clientX;
// 	var y = e.clientY;

// 	if (x < 120 && y < 83 && $('.helpText').is(':visible')) {
// 		showHelp();
// 		return;
// 	}
// }
