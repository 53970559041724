import { gameData, settings } from "./Game";
import { playSound, waveone } from "./Gamefunctions";
import { blockDestroyed } from "./WaveGen";
import { consolidateBlocks } from "./checking";

//remember to update history function to show the respective iter speeds
export function update(dt: number) {
	gameData.MainHex.dt = dt;
	if (gameData.gameState == 1) {

		waveone.update();
		if (gameData.MainHex.ct - waveone.prevTimeScored > 1000) {
			waveone.prevTimeScored = gameData.MainHex.ct;
		}
	}
	var lowestDeletedIndex = 99;
	

	var objectsToRemove = [];
	for (let i = 0; i < gameData.blocks.length; i++) {
		gameData.MainHex.doesBlockCollide(gameData.blocks[i]);
		if (!gameData.blocks[i].settled) {
			if (!gameData.blocks[i].initializing) {
				gameData.blocks[i].distFromHex -= gameData.blocks[i].iter * dt * settings.scale;
			}
		} else if (!gameData.blocks[i].removed) {
			gameData.blocks[i].removed = 1;
		}
	}

	for (let i = 0; i < gameData.MainHex.blocks.length; i++) {
		for (let j = 0; j < gameData.MainHex.blocks[i].length; j++) {
			if (gameData.MainHex.blocks[i][j].checked ==1 ) {
				consolidateBlocks(gameData.MainHex, gameData.MainHex.blocks[i][j].attachedLane, gameData.MainHex.blocks[i][j].getIndex());
				gameData.MainHex.blocks[i][j].checked=0;
			}
		}
	}

	for (let i = 0; i < gameData.MainHex.blocks.length; i++) {
		lowestDeletedIndex = 99;
		for (let j = 0; j < gameData.MainHex.blocks[i].length; j++) {
			let block = gameData.MainHex.blocks[i][j];
			if (block.deleted == 2) {
				gameData.MainHex.blocks[i].splice(j,1);
				blockDestroyed();
				if (j < lowestDeletedIndex) lowestDeletedIndex = j;
				j--;
			}
		}

		if (lowestDeletedIndex < gameData.MainHex.blocks[i].length) {
			for (let j = lowestDeletedIndex; j < gameData.MainHex.blocks[i].length; j++) {
				gameData.MainHex.blocks[i][j].settled = 0;
			}
		}
	}

	for (let i = 0; i < gameData.MainHex.blocks.length; i++) {
		for (let j = 0; j < gameData.MainHex.blocks[i].length; j++) {
			let block = gameData.MainHex.blocks[i][j];
			gameData.MainHex.doesBlockCollide(block, j, gameData.MainHex.blocks[i]);

			if (!gameData.MainHex.blocks[i][j].settled) {
				gameData.MainHex.blocks[i][j].distFromHex -= block.iter * dt * settings.scale;
			}
		}
	}

	for(let i = 0 ; i < gameData.blocks.length ; i++){
		
		if (gameData.blocks[i].removed === 1) {
			gameData.blocks.splice(i,1);
			i--;
		}
	}

	gameData.MainHex.ct += dt;
}
