import { gameData } from "./Game";
import { renderText } from "./view";

export class Text {
	x: any;
	y: any;
	font: string;
	color: any;
	opacity: number;
	text: any;
	alive: number;
	incrementFunction: CallableFunction
	constructor(x: number, y: number, text: string, font: string, color: string, incrementFunction: CallableFunction) {
		this.incrementFunction = incrementFunction
		this.x = x;
		this.y = y;
		this.font = 'linearicons';
		this.color = color;
		this.opacity =1;
		this.text = text;
		this.alive=1;
	}
	draw = function(){
		if (this.alive>0) {
			gameData.ctx.globalAlpha = this.opacity;
			// renderText((this.x + gameData.gdx), (this.y + gameData.gdy),50,this.color,this.text);
			gameData.ctx.globalAlpha =1;
			this.incrementFunction(this);
			return true;
		}
		else {
			return false;
		}
	};
}

export function fadeUpAndOut(text: { opacity: number; alive: any; y: number; }) {
	text.opacity -= gameData.MainHex.dt * Math.pow(Math.pow((1-text.opacity), 1/3)+1,3)/100;
	text.alive = text.opacity;
	text.y -= 3 * gameData.MainHex.dt;
}
