import { gameData, settings } from './Game'
import { waveone } from "./Gamefunctions";
import { Hex } from './Hex';
import * as $ from "jquery";
import { Block } from './Block';
import { JSONfn } from './jsonfn';
import { GameState } from './GameState';

export interface IGameState {
	hex: Hex,
	blocks: Array<any>,
	score: number,
	wavegen: any,
	gdx: number,
	gdy: number,
	comboTime: number
}

export function exportSaveState() {
	let state: IGameState;

	if(gameData.gameState === GameState.PLAY || gameData.gameState === GameState.PAUSED || (gameData.gameState === GameState.START && localStorage.getItem('saveState') !== undefined)) {
		state = {
			hex: $.extend(true, {}, gameData.MainHex),
			blocks: $.extend(true, [], gameData.blocks),
			score: gameData.score,
			wavegen: waveone,
			gdx: gameData.gdx,
			gdy: gameData.gdy,
			comboTime:settings.comboTime
		} ;

		state.hex.blocks.map(function(a) {
			for (var i = 0; i < a.length; i++) {
				a[i] = $.extend(true, {}, a[i]);
			}

			a.map(descaleBlock);
		});

		for (var i = 0; i < state.blocks.length; i++) {
			state.blocks[i] =  $.extend(true, {}, state.blocks[i]);
		}

		state.blocks.map(descaleBlock);
	}

	let response = JSONfn.stringify(state);
	return response;
}

function descaleBlock(b: Block) {
	b.distFromHex /= settings.scale;
}

export function clearSaveState() {
	localStorage.setItem("saveState", "{}");
}

export function isStateSaved() {
	return localStorage.getItem("saveState") !== "{}" && localStorage.getItem("saveState") != undefined;
}
