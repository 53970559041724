import { renderText, drawPolygon } from "./view";
import { drawTimer } from "./comboTimer";
import { gameData, settings } from "./Game";
import { GameState } from "./GameState";

export const FONTAWESOME = {
	faPlay: 0xf04b,
};

// Render the  screen
export function render() {
	// Clear context of canvas
	gameData.ctx.clearRect(
		0,
		0,
		gameData.trueCanvas.width,
		gameData.trueCanvas.height
	);

	drawOuterHex();
	drawInnerHex();
	drawPlacedBlocks();
	drawFloatingBlocks();
	gameData.MainHex.draw(); // Draw center main hex
	drawScoreboard(); // Draw score in center
	addTexts(); // If texts are to be shown
	fadeBeginingTexts(); // if time is low show beginning texts and fade out after some time
	addPauseOverlay();

	// Store scale
	settings.prevScale = settings.scale;

	// Store hexWidth
	settings.hexWidth = settings.baseHexWidth * settings.scale;

	// Store blockheight
	settings.blockHeight = settings.baseBlockHeight * settings.scale;
}

function drawPlacedBlocks() {
	// draw all positioned blocks
	for (let i = 0; i < gameData.MainHex.blocks.length; i++) {
		for (let j = 0; j < gameData.MainHex.blocks[i].length; j++) {
			gameData.MainHex.blocks[i][j].draw(true, j);
		}
	}
}

function drawFloatingBlocks() {
	// Draw all floating block
	for (let i = 0; i < gameData.blocks.length; i++) {
		gameData.blocks[i].draw();
	}
}

function drawOuterHex() {
	drawPolygon(
		gameData.trueCanvas.width / 2, // H center
		gameData.trueCanvas.height / 2, // V center
		6, // number of sides
		gameData.trueCanvas.width / 2, // half screen radius
		30, // Theta - rotate 30
		gameData.hexagonBackgroundColor, // Fill color
		0, // no line width
		"rgba(0,0,0,0)" // black transparent line
	);
}

function addTexts() {
	// Texts to show
	for (let i = 0; i < gameData.MainHex.texts.length; i++) {
		let alive = gameData.MainHex.texts[i].draw();
		if (!alive) {
			gameData.MainHex.texts.splice(i, 1);
			i--;
		}
	}
}

function addPauseOverlay() {
	if (gameData.gameState === GameState.PAUSED) {
		// gameData.ctx.globalAlpha = 0.9;
		// gameData.ctx.fillStyle = 'rgb(236,240,241)';
		// gameData.ctx.fillRect(0, 0, gameData.trueCanvas.width, gameData.trueCanvas.height);
		// gameData.ctx.globalAlpha = 1;
	}
}

function fadeBeginingTexts() {
	if (
		gameData.MainHex.ct < 650 &&
		gameData.gameState !== GameState.START &&
		!gameData.MainHex.playThrough
	) {
		if (gameData.MainHex.ct > 650 - 50) {
			gameData.ctx.globalAlpha =
				(50 - (gameData.MainHex.ct - (650 - 50))) / 50;
		}

		if (gameData.MainHex.ct < 50) {
			gameData.ctx.globalAlpha = gameData.MainHex.ct / 50;
		}

		renderBeginningText();
		gameData.ctx.globalAlpha = 1;
	}
}

function drawInnerHex() {
	let grey = "#ced2d6";
	if (gameData.gameState === GameState.START) {
		// Other grey
		grey = "rgb(206, 210, 214)";
	}
	if (gameData.op < 1) {
		gameData.op += 0.01;
	}

	gameData.ctx.globalAlpha = gameData.op;
	drawPolygon(
		gameData.trueCanvas.width / 2,
		gameData.trueCanvas.height / 2,
		6,
		settings.rows * settings.blockHeight * (2 / Math.sqrt(3)) +
			settings.hexWidth,
		30,
		grey,
		0,
		"6"
	);
	drawTimer();
	gameData.ctx.globalAlpha = 1;
}

function drawScoreboard() {
	if (
		gameData.gameState !== GameState.PLAY &&
		gameData.gameState !== GameState.PAUSED &&
		gameData.gameState !== GameState.START
	) {
		return;
	}
	if (gameData.scoreOpacity < 1) {
		gameData.scoreOpacity += 0.01;
		gameData.textOpacity += 0.01;
	}
	gameData.ctx.globalAlpha = gameData.textOpacity;
	var scoreSize = settings.scoreFontSize;
	var scoreString = String(gameData.score);
	// if (scoreString.length == 6) { // SCORE SIZEneeds to be adjusted?
	// 	scoreSize = 43;
	// } else if (scoreString.length == 7) {
	// 	scoreSize = 35;
	// } else if (scoreString.length == 8) {
	// 	scoreSize = 31;
	// } else if (scoreString.length == 9) {
	// 	scoreSize = 27;
	// }
	var color = settings.scoreColor;

	// var fontSize = settings.platform == 'mobile' ? 35 : 30;// this needs to be adjusted???
	// var h = gameData.trueCanvas.height / 2 + gameData.gdy + 100 * settings.scale;
	if (gameData.gameState === GameState.START) {
		// renderText(gameData.trueCanvas.width / 2 + gameData.gdx + 6 * settings.scale, gameData.trueCanvas.height / 2 + gameData.gdy, 60, "rgb(236, 240, 241)", String.fromCharCode(FONTAWESOME.faPlay));
		//renderText(trueCanvas.width / 2 + gdx + 6 * settings.scale, trueCanvas.height / 2 + gdy - 155 * settings.scale, 150, "#2c3e50", "Hextris");
		renderText(
			gameData.trueCanvas.width / 2 + gameData.gdx,
			gameData.trueCanvas.height / 2 +
				gameData.gdy +
				settings.scoreOffsetY,
			scoreSize,
			color,
			"0"
		);
	} else if (
		gameData.gameState !== GameState.PLAY &&
		gameData.textOpacity > 0
	) {
		gameData.textOpacity -= 0.05;
		// renderText(gameData.trueCanvas.width / 2 + gameData.gdx + 6 * settings.scale, gameData.trueCanvas.height / 2 + gameData.gdy, 60, "rgb(236, 240, 241)", String.fromCharCode(FONTAWESOME.faPlay));
		//renderText(trueCanvas.width / 2 + gdx + 6 * settings.scale, trueCanvas.height / 2 + gdy - 155 * settings.scale, 150, "#2c3e50", "Hextris");
		gameData.ctx.globalAlpha = gameData.scoreOpacity;
		renderText(
			gameData.trueCanvas.width / 2 + gameData.gdx,
			gameData.trueCanvas.height / 2 +
				gameData.gdy +
				settings.scoreOffsetY,
			scoreSize,
			color,
			"0"
		);
	} else {
		gameData.ctx.globalAlpha = gameData.scoreOpacity;
		renderText(
			gameData.trueCanvas.width / 2 + gameData.gdx,
			gameData.trueCanvas.height / 2 +
				gameData.gdy +
				settings.scoreOffsetY,
			scoreSize,
			color,
			scoreString
		);
	}

	gameData.ctx.globalAlpha = 1;
}

function renderBeginningText() {
	let upperheight =
		gameData.trueCanvas.height / 2 -
		settings.rows * settings.blockHeight * (2 / Math.sqrt(3)) * (5 / 6);
	let lowerheight =
		gameData.trueCanvas.height / 2 +
		settings.rows * settings.blockHeight * (2 / Math.sqrt(3)) * (11 / 16);
	let text = "";
	let mob;
	let fontSize;
	let input_text;
	let action_text;
	let score_text;
	if (
		/mobile|Mobile|iOS|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		)
	) {
		mob = true;
		input_text = ""; //'Tap the screen\'s left and ride'
		action_text = ""; //'sides to rotate the hexagon'
		score_text = ""; //'Match 3+ blocks to score'
		fontSize = 35;
	} else {
		mob = false;
		input_text = ""; //'Use the right and left arrow keys'
		action_text = ""; //to rotate the hexagon'
		score_text = ""; //'Match 3+ blocks to score!'
		fontSize = 27;
	}
	renderText(
		gameData.trueCanvas.width / 2 + 2 * settings.scale,
		upperheight - 0 * settings.scale,
		fontSize,
		"#2c3e50",
		input_text
	);
	renderText(
		gameData.trueCanvas.width / 2 + 2 * settings.scale,
		upperheight + 33 * settings.scale,
		fontSize,
		"#2c3e50",
		action_text
	);
	if (!mob) {
		drawKey(
			"",
			gameData.trueCanvas.width / 2 + 2 * settings.scale - 2.5,
			upperheight + 38 * settings.scale
		);
	}

	renderText(
		gameData.trueCanvas.width / 2 + 2 * settings.scale,
		lowerheight,
		fontSize,
		"#2c3e50",
		score_text
	);
}

function drawKey(key: string, x: number, y: number) {
	gameData.ctx.save();
	switch (key) {
		case "left":
			gameData.ctx.translate(x, y + settings.scale * 13);
			gameData.ctx.rotate(3.14159);
			gameData.ctx.font = "20px Fontawesome";
			gameData.ctx.scale(settings.scale, settings.scale);
			gameData.ctx.fillText(
				String.fromCharCode(FONTAWESOME.faPlay),
				0,
				0
			);
			break;
		case "right":
			gameData.ctx.font = "20px Fontawesome";
			gameData.ctx.translate(x, y + settings.scale * 27.5);
			gameData.ctx.scale(settings.scale, settings.scale);
			gameData.ctx.fillText(
				String.fromCharCode(FONTAWESOME.faPlay),
				0,
				0
			);
			break;

		default:
			drawKey("left", x - 5, y);
			drawKey("right", x + 5, y);
	}
	gameData.ctx.restore();
}
