import { GameState } from "./GameState";
import { set_score_pos } from "./Gamefunctions";
import { rotatePoint } from "./math";
import * as $ from "jquery";
import { FONTAWESOME } from "./render";
import { Hextrix, gameData, settings } from "./Game";

// Allow pause on start
var pausable = true;

// t: current time, b: begInnIng value, c: change In value, d: duration
// function easeOutCubic(t, b, c, d) {
// 	return c * ((t = t / d - 1) * t * t + 1) + b;
// }

export function renderText(
	x: number,
	y: number,
	fontSize: number,
	color: string,
	text: string
) {
	gameData.ctx.save();
	//if (!font) {

	//	}

	var font = "px " + settings.scoreFont; // needs to be adjusted

	if (!fontSize) {
		fontSize = 20 * settings.scale;
	} else {
		fontSize = fontSize * settings.scale;
	}
	gameData.ctx.font = fontSize + font;
	gameData.ctx.textAlign = "center";
	gameData.ctx.fillStyle = color;
	//console.log(text);
	//if(text != "Hextris" && text != 0")
	gameData.ctx.fillText(text, x, y + fontSize / 2 - 9 * settings.scale);
	gameData.ctx.restore();
}

export function drawPolygon(
	x: number,
	y: number,
	sides: number,
	radius: number,
	theta: number,
	fillColor: string | CanvasGradient | CanvasPattern,
	lineWidth: number,
	lineColor: string | CanvasGradient | CanvasPattern
) {
	let ctx = gameData.ctx;
	ctx.fillStyle = fillColor;
	ctx.lineWidth = lineWidth;
	ctx.strokeStyle = lineColor;

	ctx.beginPath();
	let coords = rotatePoint(0, radius, theta);
	ctx.moveTo(coords.x + x, coords.y + y);
	let oldX = coords.x;
	let oldY = coords.y;
	for (let i = 0; i < sides; i++) {
		coords = rotatePoint(oldX, oldY, 360 / sides);
		ctx.lineTo(coords.x + x, coords.y + y);
		oldX = coords.x;
		oldY = coords.y;
	}

	ctx.closePath();
	ctx.fill();
	ctx.stroke();
	ctx.strokeStyle = "rgba(0,0,0,0)";
}

type textTypes =
	| "paused"
	| "pausedAndroid"
	| "pausediOS"
	| "pausedOther"
	| "start"
	| "gameover";
function showText(text: textTypes) {
	const messages = {
		paused:
			"<div class='centeredHeader unselectable' style='background-color:" +
			settings.overlayColor +
			";font-family: " +
			settings.messageFont +
			";font-size: " +
			settings.messageFontSize +
			"px;line-height: " +
			settings.messageFontSize +
			"px; color: " +
			settings.messageColor +
			"' >" +
			settings.message +
			"</div>",
		start:
			"<div class='centeredHeader unselectable' style='line-height:80px;' style='background-color:" +
			settings.overlayColor +
			"'; font-family: '" +
			settings.scoreFont +
			"'>Press enter to start</div>",
		gameover: "",
	};

	// if (text == 'paused') {
	// 	if (settings.os == 'android') {
	// 		text = 'pausedAndroid'
	// 	} else if (settings.os == 'ios') {
	//         text = 'pausediOS'
	//     } else if (settings.platform == 'nonmobile') {
	//         text = 'pausedOther'
	//     }
	// }

	if (text === "gameover") {
		//Clay('client.share.any', {text: 'Think you can beat my score of '+ score + ' in Super Cool Game?'})
		$("#gameoverscreen").fadeIn();
	}
	$(".overlay").html(messages[text]);
	$(".overlay").fadeIn(1000, "swing");
}

export function hideText() {
	$(".overlay").fadeOut(150, function () {
		$(".overlay").html("");
	});
}

export function BlurCanvas(blur: boolean) {
	document.getElementById("canvas").className = blur ? "blur" : "";
}

export function gameOverDisplay() {
	// GAMECONSOLE
	// top.gameConsole.showEndScreen(score, 0);
	// set_score_pos();
}

export function showPause(show: boolean) {
	// console.log('pause the game!');
	// if (gameData.gameState === GameState.START || gameData.gameState == GameState.GAMEOVER || !pausable) {
	//     return;
	// }

	// Not able to pause again
	pausable = false;

	//$('#restartBtn').fadeIn(300, "linear");
	//$('#buttonCont').fadeIn(300, "linear");
	//$('.helpText').fadeIn(300, 'linear');
	if (show) {
		$("#fork-ribbon").fadeIn(300, "linear");
		// $("#pauseBtn").attr("src","./images/btn_resume.svg");
		$("#overlay").fadeIn(300, "linear");
		showText("paused");
	} else {
		$("#fork-ribbon").fadeOut(300, "linear");
		$("#overlay").fadeOut(300, "linear");
		hideText();
	}
	return;
}
